.mat-tab-group {
  .mat-tab-nav-bar,
  .mat-tab-header {
    border: none;
  }
  .mat-tab-header {
    margin-bottom: 10px;

    .mat-ink-bar {
      height: 3px;
    }

    .mat-tab-header-pagination.mat-tab-header-pagination-after,
    .mat-tab-header-pagination.mat-tab-header-pagination-before {
      display: none;
    }
  }

  .mat-tab-label-container {
    .mat-tab-label {
      height: 36px;
      padding: 0 20px;
      opacity: 1;
      min-width: 0;
    }
  }
}

.mat-tab-group.comparison-tab-group {
  .mat-tab-header {
    align-self: flex-end;
    position: relative;
    bottom: 15px;
  }
  .mat-tab-body-wrapper {
    height: 100%;
    .mat-tab-body {
      height: 100%;
      .mat-tab-body-content {
        display: flex;
        align-items: center;
      }
    }
  }
}
