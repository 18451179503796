@use '@angular/material' as mat;

@mixin am-analysis-results-typography($config) {
  am-analysis-results {
    .header {
      .analysis-toggle {
        .toggle {
          font-family: mat.m2-font-family($config, button);
          font-size: mat.m2-font-size($config, button);
          line-height: 32px;
        }
      }

      .action-buttons button {
        font-family: mat.m2-font-family($config, display-1);
      }
    }

    @media only screen and (max-width: $large-tablet-size-down) {
      .header {
        .analysis-toggle {
          .toggle {
            font-size: mat.m2-font-size($config, subheading-1);
            line-height: 28px;
          }
        }
      }
    }
  }
}
