@use '@angular/material' as mat;

@mixin am-tooltip-modal-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);

  am-tooltip-modal {
    .tooltips-content {
      .word {
        color: $primary;
      }
    }
  }
}

@mixin am-tooltip-modal-typography($config) {
  am-tooltip-modal {
    .tooltips-content {
      .word {
        font-weight: mat.m2-font-weight($config, body-2);
      }
    }
  }
}
