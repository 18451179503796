@use '@angular/material' as mat;

@mixin am-exante-analysis-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);

  am-exante-analysis {
    .allocation-name.mat-subheading-1.active {
      color: $primary;
    }
  }
}

@mixin am-exante-analysis-typography($config) {
  am-exante-analysis {
    .allocation-name.mat-subheading-1:not(.active) {
      font-weight: mat.m2-font-weight($config, body-1);
    }
  }
}
