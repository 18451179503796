@use '@angular/material' as mat;

@mixin am-contribution-theme($theme) {
  $palette: map-get($theme, primary);

  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);

  am-contribution {
    .contribution-container {
      background-color: $contrast;
    }
  }
}
