@use '@angular/material' as mat;
@import '../../../../styles/core/layout/breakpoints';

@mixin am-allocation-analytics-theme($theme) {
  $palette: map-get($theme, primary);

  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);

  am-allocation-analytics {
    .analytics-container {
      .analytics {
        background-color: $contrast;
      }
    }
  }
}

@mixin am-allocation-analytics-typography($config) {
  am-allocation-analytics {
    .analytics-container {
      font-family: mat.m2-font-family($config, body-1);
      font-size: mat.m2-font-size($config, body-bigger);
    }
  }
}
