@use '@angular/material' as mat;

@mixin am-simulation-icon-theme($theme) {
  $palette: map-get($theme, primary);

  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);

  .svg-icon {
    fill: $contrast;
    .stroke-fill {
      stroke: $contrast;
    }
  }

  .svg-icon.icon-active {
    fill: $contrast;
    .stroke-fill {
      stroke: $contrast;
    }
  }
}
