@use '@angular/material' as mat;

@mixin am-tabs-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);

  .mat-mdc-tab-header {
    --mdc-secondary-navigation-tab-container-height: 36px;
    --mat-tab-header-active-ripple-color: #{$contrast};
    --mat-tab-header-inactive-ripple-color: #{$contrast};
  }

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $primary;
  }
}
