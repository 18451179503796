@use '@angular/material' as mat;

@mixin am-telemetry-theme($theme) {
  $palette: map-get($theme, primary);

  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);
  $lightContrast: mat.m2-get-color-from-palette($palette, A100);

  am-telemetry {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
      --mdc-shape-small: 0;
      --mdc-outlined-text-field-container-shape: 0;
    }

    .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
      --mat-icon-button-state-layer-color: #{$contrast};
    }
  }

  .mat-calendar-body-disabled {
    color: $lightContrast;
  }
}
