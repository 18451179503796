.mat-menu-panel.export-menu {
  min-width: 70px;
  .mat-menu-item.export-menu-button {
    height: 36px;
    line-height: 36px;
    text-align: center;
    .export-icon {
      height: 24px;
      vertical-align: middle;
    }
  }
  .mat-menu-item.export-menu-button:not(:last-child) {
    margin-bottom: 4px;
  }
}
.mat-drawer-backdrop {
  background: rgba(0, 0, 0, 0.6);
}
