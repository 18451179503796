@use '@angular/material' as mat;

@mixin am-home-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);
  $lightPrimary: mat.m2-get-color-from-palette($palette, 100);

  am-home {
    .container {
      .displayed-projects,
      .search-form-field {
        color: $primary;

        input::placeholder {
          color: $primary;
        }
      }

      .search-form-field {
        .mat-mdc-text-field-wrapper {
          background-color: $contrast;
          box-shadow: 0 2px 8px 0 rgba(102, 102, 102, 0.32);
        }
      }

      .project-card {
        .material-icons {
          color: $contrast;
          background-color: $primary;
        }

        .material-icons.unarchive {
          color: $primary;
          background-color: $contrast;
        }

        .home-card-button {
          background-color: $lightPrimary;
        }
      }
    }
  }
}

@mixin am-home-typography($config) {
  am-home {
    .container {
      .displayed-projects {
        .material-icons {
          font-size: mat.m2-font-size($config, body-bigger);
        }
      }

      .project-card {
        .material-icons {
          font-size: mat.m2-font-size($config, subheading-2);
        }

        .material-icons.unarchive {
          font-size: 24px;
        }

        .project-infos {
          font: {
            family: mat.m2-font-family($config, body-1);
          }

          .project-infos-value {
            font: {
              family: mat.m2-font-family($config, body-1);
              size: mat.m2-font-size($config, title);
              weight: mat.m2-font-weight($config, title);
            }
          }
        }

        .home-card-button {
          font: {
            family: mat.m2-font-family($config, title);
            size: mat.m2-font-size($config, body-1);
            weight: mat.m2-font-weight($config, body-1);
          }
        }
      }
    }

    .no-project {
      .material-icons {
        font-size: mat.m2-font-size($config, huge-icon);
      }
    }
  }
}
