@use '@angular/material' as mat;

@mixin am-form-field-theme($theme) {
  $white: map-get($am-white, 400);
  $offWhite: map-get($am-white, 700);
  $green: map-get($am-green, 400);
  $lightGrey: map-get($am-grey, 100);
  $orange: map-get($am-orange, 400);

  .am-form-field {
    &.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
      color: $lightGrey;
      background-color: $offWhite;
    }

    &.mat-form-field-appearance-fill {
      .mat-form-field-flex {
        background-color: $green;

        .mat-select-value,
        .mat-select-arrow {
          color: $white;
        }
      }
    }

    &.mat-select-disabled .mat-select-value,
    .mat-input-element:disabled {
      color: $lightGrey;
      opacity: 1;
      -webkit-text-fill-color: $lightGrey;
      -webkit-opacity: 1;
      font-weight: bold;
    }
  }

  .am-lang-form-field.am-orange-select.mat-select-panel.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: orange;
  }

  .am-lang-form-field .am-lang-select.mat-select .mat-select-value {
    color: $green;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: rgba(0, 0, 0, 0.12);
  }
}

@mixin am-form-field-typography($config) {
  input {
    font-family: mat.m2-font-family($config, input);
  }

  .am-lang-select.mat-select .mat-select-value {
    font: {
      size: mat.m2-font-size($config, button);
      weight: mat.m2-font-weight($config, button);
    }
  }
}
