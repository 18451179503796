@use '@angular/material' as mat;

@mixin am-tabs-typography($config) {
  .mat-mdc-tab {
    --mat-tab-header-label-text-size: #{mat.m2-font-size($config, subheading-1)};
    --mat-tab-header-label-text-weight: #{mat.m2-font-weight($config, body-1)};

    &.mdc-tab__text-label {
      font: {
        family: mat.m2-font-family($config, subheading-1);
        size: mat.m2-font-size($config, subheading-1);
      }
    }

    &.mdc-tab--active .mdc-tab__text-label {
      font-weight: 700;
    }
  }

  .mat-tab-group {
    .mat-tab-label {
      font: {
        size: mat.m2-font-size($config, subheading-1);
        weight: mat.m2-font-weight($config, body-1);
      }
    }

    .mat-tab-label-active {
      font-weight: mat.m2-font-weight($config, subheading-1);
    }
  }
}
