.mat-mdc-form-field {
  --mat-form-field-container-text-weight: 400;

  &.am-static-prefix.mat-form-field .mat-form-field-prefix,
  &.am-static-suffix.mat-form-field .mat-form-field-suffix {
    position: static;
  }

  .mat-form-field-underline {
    display: none;
  }

  &.mat-form-field-appearance-fill {
    margin: 0.25em 0;

    .mat-form-field-flex {
      padding: 0 0.75em 0 0.75em;
      border-radius: 0;
    }
  }

  &.mat-form-field-appearance-standard {
    .mat-form-field-wrapper {
      padding-bottom: 0.6em;
    }
    .mat-form-field-flex {
      padding-top: 0;
    }
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-outline-start,
    .mat-form-field-outline-end {
      border-radius: 0;
    }
    .mat-form-field-infix {
      padding: 0.6em 0 0.8em 0;
      width: 100%;
    }
  }

  &.mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0.1em 0 0.7em 0;
    width: 100%;
  }

  .mat-select-disabled .mat-select-value,
  .mat-input-element:disabled {
    line-height: 16px;
    margin-top: -0.2em;
  }

  &.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline,
  &.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-outline {
    border-radius: 0;
  }

  &.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: none;
  }

  .mat-select .mat-select-trigger {
    height: 1.25em;
  }
}
.mat-select-panel {
  background: white;
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.am-bigger-select.mat-select-panel {
  max-width: 330px;
  height: 50vh;
  max-height: 400px;
}

.am-lang-form-field {
  .mat-form-field-infix {
    padding: 0;
  }
  .am-lang-select.mat-select .mat-select-trigger {
    width: 100%;
    .mat-select-arrow {
      display: none;
    }
  }
}

.am-display-flex.material-icons {
  display: flex;
}
