$border-size: 12px;

.am-charts-tooltip:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-right: $border-size solid transparent;
  border-left: $border-size solid transparent;
  border-top-width: $border-size;
  border-top-style: solid;
  position: absolute;
  top: 100%;
  left: calc(50% - #{$border-size});
  border-top-color: rgba(44, 44, 44, 0.85);
}

.am-charts-tooltip-header {
  padding: 8px 10px;
  text-align: center;
}

.am-charts-tooltip-content {
  margin: 0px 5px;
  display: table;
  width: calc(100% - 10px);

  .row {
    display: table-row;

    .cell-label {
      display: table-cell;
      padding: 4px;
      .backtest-icon {
        width: 14px;
        height: 14px;
        display: inline-block;
        position: relative;
        top: 2px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .cell-value {
      display: table-cell;
      text-align: right;
      padding: 4px 5px 4px 12px;
    }
  }
}
