@use '@angular/material' as mat;

@font-face {
  font-family: BnpSans-Bold;
  src: url('../../../assets/fonts/BNPP Expanded Sans Bold.ttf');
}

@font-face {
  font-family: BnpSans-Regular;
  src: url('../../../assets/fonts/BNPP Expanded Sans.ttf');
}

@font-face {
  font-family: BnpSerif;
  src: url('../../../assets/fonts/bnpp_serif_regular_v2.otf');
}

// Represents a collection of typography levels.
// Defaults come from https://material.io/guidelines/style/typography.html
// Note: The spec doesn't mention letter spacing. The values here come from
// eyeballing it until it looked exactly like the spec examples.
@function am-typography-config(
  $font-family: 'BnpSerif',
  $bnp-regular: 'BnpSans-Regular',
  $bnp-bold: 'BnpSans-Bold',
  $app-name: mat.m2-define-typography-level(28px, 28px, 400, $bnp-regular),
  $app-name-small: mat.m2-define-typography-level(24px, 24px, 400, $bnp-regular),
  $display-4: mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $display-3: mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $display-2: mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $display-1: mat.m2-define-typography-level(12px, 14px, 400, $bnp-regular),
  $headline: mat.m2-define-typography-level(20px, 24px, 400, $bnp-bold),
  $title: mat.m2-define-typography-level(14px, 19px, 700, $bnp-regular),
  $subheading-2: mat.m2-define-typography-level(16px, 22px, 700, $bnp-regular),
  $subheading-1: mat.m2-define-typography-level(14px, 19px, 700, $font-family),
  $body-2: mat.m2-define-typography-level(12px, 24px, 700, $bnp-regular),
  $body-1: mat.m2-define-typography-level(12px, 20px, 400, $font-family),
  $body-bigger: mat.m2-define-typography-level(14px, 20px, 400),
  $caption: mat.m2-define-typography-level(10px, 20px, 400),
  $button: mat.m2-define-typography-level(17px, 20px, 700, $bnp-regular),
  $input: mat.m2-define-typography-level(inherit, 1.125, 400),
  $huge-icon: mat.m2-define-typography-level(36px, 1, 400),
  $very-huge-icon: mat.m2-define-typography-level(64px, 1, 400),
  $asset-panel: mat.m2-define-typography-level(17px, 23px, 400),
  $category: mat.m2-define-typography-level(17px, 23px, 700)
) {
  // Declare an initial map with all of the levels.
  $config: (
    app-name: $app-name,
    app-name-small: $app-name-small,
    display-4: $display-4,
    display-3: $display-3,
    display-2: $display-2,
    display-1: $display-1,
    headline: $headline,
    title: $title,
    subheading-2: $subheading-2,
    subheading-1: $subheading-1,
    body-2: $body-2,
    body-1: $body-1,
    body-bigger: $body-bigger,
    caption: $caption,
    button: $button,
    input: $input,
    huge-icon: $huge-icon,
    very-huge-icon: $very-huge-icon,
    asset-panel: $asset-panel,
    category: $category,
  );

  // Loop through the levels and set the `font-family` of the ones that don't have one to the base.
  // Note that Sass can't modify maps in place, which means that we need to merge and re-assign.
  @each $key, $level in $config {
    @if map-get($level, font-family) == null {
      $new-level: map-merge(
        $level,
        (
          font-family: $font-family,
        )
      );
      $config: map-merge(
        $config,
        (
          $key: $new-level,
        )
      );
    }
  }

  // Add the base font family to the config.
  @return map-merge(
    $config,
    (
      font-family: $font-family,
    )
  );
}

// Adds the base typography styles, based on a config.
@mixin am-base-typography($config, $selector: '.am-typography') {
  .mat-h1,
  .mat-headline,
  #{$selector} h1 {
    @include mat.m2-typography-level($config, headline);
  }

  .mat-h2,
  .mat-title,
  #{$selector} h2 {
    @include mat.m2-typography-level($config, title);
    margin: 0 0 16px;
  }

  .mat-h3,
  .mat-subheading-2,
  #{$selector} h3 {
    @include mat.m2-typography-level($config, subheading-2);
    margin: 0 0 16px;
  }

  .mat-h4,
  .mat-subheading-1,
  #{$selector} h4 {
    @include mat.m2-typography-level($config, subheading-1);
    margin: 0 0 16px;
  }

  .mat-body-strong,
  .mat-body-2 {
    @include mat.m2-typography-level($config, body-2);
  }

  .mat-body,
  .mat-body-1,
  #{$selector} {
    @include mat.m2-typography-level($config, body-1);

    p {
      margin: 0 0 12px;
    }
  }

  .mat-small,
  .mat-caption {
    @include mat.m2-typography-level($config, caption);
  }

  .mat-display-4,
  #{$selector} .mat-display-4 {
    @include mat.m2-typography-level($config, display-4);
    margin: 0 0 56px;
  }

  .mat-display-3,
  #{$selector} .mat-display-3 {
    @include mat.m2-typography-level($config, display-3);
    margin: 0 0 64px;
  }

  .mat-display-2,
  #{$selector} .mat-display-2 {
    @include mat.m2-typography-level($config, display-2);
    margin: 0 0 64px;
  }

  .mat-display-1,
  #{$selector} .mat-display-1 {
    @include mat.m2-typography-level($config, display-1);
    margin: 0 0 64px;
  }

  .am-app-name,
  #{$selector} .am-app-name {
    @include mat.m2-typography-level($config, app-name);
  }

  .am-app-name-small,
  #{$selector} .am-app-name-small {
    @include mat.m2-typography-level($config, app-name-small);
  }

  .am-body-bigger,
  #{$selector} .am-body-bigger {
    @include mat.m2-typography-level($config, body-bigger);
  }

  .am-loading,
  #{$selector} .am-loading {
    @include mat.m2-typography-level($config, category);
  }
}
