@use '@angular/material' as mat;

@mixin am-simulation-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);
  $lightContrast: mat.m2-get-contrast-color-from-palette($palette, 900);

  am-simulation {
    .project-button {
      color: $contrast;
      background-color: $primary;
    }

    .project-button:disabled {
      background-color: $lightContrast;
    }
  }
}

@mixin am-simulation-typography($config) {
  am-simulation {
    .header {
      .material-icons.add-icon {
        font-size: 30px;
        line-height: 36px;
      }
      .material-icons.save-icon {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
}
