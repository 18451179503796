/* You can add global styles to this file, and also import other style files */
@import 'theme';
@import 'charts-tooltip';

$darkGrey: map-get($am-grey, 800);
$allocOneGreen: map-get($am-green, 50);
$allocTwoGreen: map-get($am-green, 200);
$allocThreeGreen: map-get($am-green, 300);

body {
  display: flex;
  margin: 0;
  min-width: 748px;
  min-height: 100vh;
  font-family: 'BnpSans-Regular' !important;
  font-size: 12px;
  letter-spacing: normal !important;

  .am-root {
    width: 100%;
  }

  .mat-headline,
  .mat-h1,
  .mat-title,
  .mat-h2,
  .mat-subheading-2,
  .mat-h3,
  .mat-subheading-1,
  .mat-h4 {
    color: $darkGrey;
  }

  .graph div:first-child {
    width: 100% !important;

    canvas {
      width: 100% !important;
    }
  }
}

// IE 10 and above
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .am-root {
    height: 100%;
    min-height: 100vh;
  }
}

.material-icons {
  user-select: none;
}

input {
  background-color: unset;
  border: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

:focus {
  outline: 0;
}

.am-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  .am-spinner {
    margin: 20px auto;
  }
}

.am-box-shadow {
  box-shadow: 0 0 6px 0 rgba(102, 102, 102, 0.32) !important;
}

.am-alloc-1-color {
  color: $allocOneGreen;
}

.am-alloc-2-color {
  color: $allocTwoGreen;
}

.am-alloc-3-color {
  color: $allocThreeGreen;
}

.mat-form-field.am-lang-form-field {
  width: 40px;
  text-align: center;
}
