@use '@angular/material' as mat;
@use 'sass:color';

@mixin am-backtest-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);
  $transparentPrimary: color.adjust($primary, $alpha: 0.3);

  $orange: map-get($am-orange, 400);

  am-backtest {
    .backtest-container {
      .period-form-field {
        --mdc-filled-text-field-container-color: #{$primary};
      }

      .assets-form-field {
        --mdc-filled-text-field-container-color: #{$orange};
      }

      .backtest-analytics,
      .backtest {
        background-color: $contrast;
      }

      .backtest-analytics .mat-headline.help {
        color: $primary;
        box-shadow: -2px 2px 10px 0px $transparentPrimary;
      }
    }
  }
}

@mixin am-backtest-typography($config) {
  am-backtest {
    .backtest-parameters,
    .analytics-label {
      font-size: mat.m2-font-size($config, subheading-2);

      .material-icons {
        font-size: mat.m2-font-size($config, subheading-2);
      }

      .parameters-label {
        font-weight: mat.m2-font-weight($config, body-2);
      }

      .backtest-dates {
        font-family: mat.m2-font-family($config, body-1);
      }
    }

    .backtest-parameters {
      .mat-select.material-icons {
        font-family: 'Material Icons';
        font-size: mat.m2-font-size($config, button);
      }
    }

    .analytics-label {
      font-weight: mat.m2-font-weight($config, body-2);
    }
  }
}
