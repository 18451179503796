@use '@angular/material' as mat;

@mixin am-data-importer-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);
  $lightPrimary: mat.m2-get-color-from-palette($palette, 200);

  am-data-importer {
    .panel {
      background-color: $lightPrimary;
      color: $contrast;

      .mat-expansion-panel-header {
        background-color: $primary !important;

        .mat-expansion-panel-header-title,
        .mat-expansion-indicator:after {
          color: $contrast;
        }
      }
      .mat-progress-spinner.spinner circle {
        stroke: $contrast;
      }
    }
  }
}

@mixin am-data-importer-typography($config) {
  am-data-importer {
    .content {
      mat-icon:not(.done-icon) {
        font-size: mat.m2-font-size($config, very-huge-icon) !important;
      }
    }

    .last-import {
      font-style: italic;
    }
  }
}
