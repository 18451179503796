@use '@angular/material' as mat;

@mixin am-button-theme($theme) {
  .mat-flat-button,
  .mat-mdc-unelevated-button,
  .mat-button-base[disabled] {
    font-size: 15px;
    letter-spacing: normal;
  }

  .mat-flat-button[disabled] {
    font-size: 12px;
  }
  .mat-icon-button {
    background: 0;
  }

  .mat-fab.mat-primary,
  .mat-flat-button.mat-primary,
  .mat-mini-fab.mat-primary,
  .mat-raised-button.mat-primary {
    color: white;
    background-color: $green;
    &:disabled {
      color: mat.m2-get-color-from-palette($am-grey, 100);
      background-color: #ebeef0;
    }
  }

  .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before {
    --mat-text-button-hover-state-layer-opacity: 0;
  }

  .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before {
    --mat-text-button-pressed-state-layer-opacity: 0;
  }

  @media only screen and (max-width: $large-tablet-size-up) {
    .mat-flat-button,
    .mat-button-base[disabled] {
      font-size: 14px;
    }
  }
}
