.mdc-dialog__title {
  --mat-dialog-headline-padding: 15px 24px;
  display: block;
  margin-bottom: 20px !important;

  &::before {
    height: unset !important;
  }
}

.mat-mdc-unelevated-button {
  --mdc-filled-button-label-text-font: 'BnpSans-Regular';
  --mdc-filled-button-label-text-size: 17px;
  --mdc-filled-button-label-text-weight: 700;
}

.mdc-dialog__actions {
  --mat-dialog-actions-padding: 15px 24px;

  button:last-child {
    margin-left: 45px !important;
  }
}
