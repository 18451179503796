@use '@angular/material' as mat;
@import '../../../../styles/core/layout/breakpoints';

@mixin am-sidebar-theme($theme) {
  $palette: map-get($theme, primary);

  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);

  am-sidebar {
    .logo-SPA,
    .logo-ASIA,
    .logo-CH,
    .logo-LUX {
      background-color: $contrast;
    }

    .mdc-list-item:focus::before {
      --mdc-list-list-item-focus-state-layer-opacity: 0;
    }

    .am-nav-list,
    .bottom-nav-list {
      .menu-name,
      .material-icons {
        color: $contrast;
      }

      .clear {
        border-bottom-color: rgba(0, 0, 0, 0.12);
      }

      .active {
        box-shadow: inset 5px 0 $contrast;
      }
    }
  }
}

@mixin am-sidebar-typography($config) {
  am-sidebar {
    .material-icons {
      font-size: mat.m2-font-size($config, huge-icon);
    }

    .am-nav-list-item {
      font-family: mat.m2-font-family($config, body-2);
    }

    @media only screen and (min-width: $small-desktop-size-up) {
      .material-icons {
        font-size: 42px;
      }
      .menu-name {
        font-size: mat.m2-font-size($config, body-bigger);
      }
    }
  }
}
