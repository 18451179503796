@use '@angular/material' as mat;
@import '../../../../styles/core/layout/breakpoints';

@mixin am-layout-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);
  $otherContrast: mat.m2-get-contrast-color-from-palette($palette, 800);

  .mat-menu-content button {
    background-color: $contrast;
  }
  am-layout {
    .sidenav {
      background-color: $primary;
    }
    .sidenav-container {
      background: linear-gradient(135deg, $contrast 0%, $otherContrast 100%);
      .header-brand .header-app-name,
      .mat-subheading-1.user-icon,
      .export-button,
      .user-button .mat-subheading-1 {
        color: $primary;
      }
    }
  }
  .export-spinner svg * {
    stroke: $primary;
  }
}

@mixin am-layout-typography($config) {
  am-layout {
    .header {
      .material-icons {
        font-size: mat.m2-font-size($config, huge-icon);
      }
    }

    @media only screen and (max-width: $large-tablet-size-down) {
      .header-app-name {
        font-size: mat.m2-font-size($config, app-name-small);
      }
    }
  }
}
