@use '@angular/material' as mat;
@use 'sass:color';

@import '../../../../styles/core/layout/breakpoints';

@mixin am-performance-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  $transparentPrimary: color.adjust($primary, $alpha: 0.3);

  am-performance {
    .mat-headline.help {
      color: $primary;
      box-shadow: -2px 2px 10px 0px $transparentPrimary;
    }
  }
}

@mixin am-performance-typography($config) {
  am-performance {
    .analytics-subtitle {
      font: {
        size: mat.m2-font-size($config, body-2);
        weight: mat.m2-font-weight($config, body-2);
      }
    }

    .performance {
      font-size: 42px;
      font-weight: bold;
    }

    @media only screen and (max-width: $large-tablet-size-down) {
      font-size: mat.m2-font-size($config, body-1);

      .performance {
        font-size: 36px;
      }
    }
  }
}
