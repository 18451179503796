@use '@angular/material' as mat;
@mixin am-financial-profiles-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);
  $darkContrast: mat.m2-get-contrast-color-from-palette($palette, A200);

  am-financial-profiles {
    --mat-standard-button-toggle-hover-state-layer-opacity: 0;

    .financial-profiles-container {
      background-color: $contrast;

      .profiles-toggle-group {
        .mat-button-toggle.profiles-toggle-button {
          color: $darkContrast;
          background-color: $contrast !important;
        }
        .mat-button-toggle-checked.profiles-toggle-button {
          background-color: $primary !important;
        }
      }
    }
  }
}

@mixin am-financial-profiles-typography($config) {
  am-financial-profiles {
    .mat-button-toggle-label-content {
      font-size: 12px;
    }
  }
}
