.am-snack-bar.mat-mdc-snack-bar-container {
  max-width: none;
  width: 33vw;
  margin-bottom: 32px;

  .mdc-snackbar__surface {
    max-width: unset;
    width: 100%;
  }

  .mdc-snackbar__label {
    padding: 0 8px 0 16px;
  }
}

@media only screen and (max-width: $small-desktop-size-down) {
  .am-snack-bar.mat-mdc-snack-bar-container {
    width: 50vw;
  }
}

@media only screen and (max-width: $large-tablet-size-down) {
  .am-snack-bar.mat-mdc-snack-bar-container {
    width: 70vw;
  }
}
