.mat-button-toggle {
  --mat-standard-button-toggle-shape: 0;

  .mat-button-toggle-button {
    height: 37px;
    min-width: 75px;

    .mat-button-toggle-label-content {
      line-height: 37px;
    }
  }

  // IE 10 and above
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .mat-button-toggle-button {
      height: 36px;
    }
  }
}
