@use '@angular/material' as mat;

@mixin am-project-details-theme($theme) {
  $palette: map-get($theme, primary);

  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);
  $otherContrast: mat.m2-get-contrast-color-from-palette($palette, 800);
  $disabled: mat.m2-get-contrast-color-from-palette($palette, 900);

  am-project-details {
    .project-form {
      --mat-select-enabled-trigger-text-color: $otherContrast;
      --mat-select-enabled-arrow-color: black;
      --mat-select-focused-arrow-color: black;
      --mdc-shape-small: 0;

      background-color: $contrast;

      .mat-form-field-disabled .mat-mdc-text-field-wrapper {
        background-color: $disabled;
      }

      .invalid-form-container {
        .invalid-form-field {
          box-shadow: inset 3px 0 $C14;
        }
      }
    }
  }
  .mat-select-panel {
    border: 1px solid $otherContrast;
  }
}

@mixin am-project-details-typography($config) {
  am-project-details {
    .project-form {
      .invalid-form-container {
        .invalid-form-field {
          font: {
            family: mat.m2-font-family($config, input);
            size: mat.m2-font-size($config, input);
            style: italic;
          }
        }
      }
    }
  }
}
