@use '@angular/material' as mat;

$white: #ffffff; // components background / application's background (first gradient)
$am-white: (
  50: $white,
  100: $white,
  200: $white,
  300: $white,
  400: $white,
  // old application's background
  500: #f9f9f9,
  // old  application's background
  600: #f9f9f9,
  // inactives buttons/actions/assets
  700: #ebeef0,
  // charts tooltip background
  800: #efeff4,
  // application's background (second gradient) / inactives toggle buttons
  900: #e5ebe9,
  A100: $white,
  A200: $white,
  A400: $white,
  A700: $white,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: rgba(black, 0.87),
    900: rgba(black, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  ),
);

$grey: #2c2c2c; // texts
$am-grey: (
  // login page divider
  50: #dee5ea,
  // inactives texts
  100: #a5a5a5,
  // border
  200: #8e8e93,
  // border
  300: #8e8e93,
  400: #5e6261,
  500: $grey,
  600: $grey,
  700: $grey,
  800: $grey,
  900: $grey,
  A100: $grey,
  A200: $grey,
  A400: $grey,
  A700: $grey,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: rgba(black, 0.87),
    900: rgba(black, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  )
);

$green: #006a4e; // main color and actives buttons/actions
$am-green: (
  // first allocation
  50: #99b0a6,
  // home page button
  100: #7f9c90,
  // second allocation
  200: #6c8578,
  // third allocation
  300: #00816d,
  400: $green,
  500: $green,
  600: $green,
  700: $green,
  800: $green,
  900: #1d4e89,
  A100: $green,
  A200: $green,
  A400: $green,
  A700: $green,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: #e5ebe9,
    900: #ebeef0,
    A100: #efeff4,
    A200: rgba(black, 0.87),
    A400: $grey,
    A700: #e5ebe9,
  )
);

$purple: #642f6c; // charts color 1
$am-purple: (
  50: lighten($purple, 50%),
  100: $purple,
  200: lighten($purple, 30%),
  300: lighten($purple, 10%),
  400: $purple,
  500: $purple,
  600: $purple,
  700: $purple,
  800: $purple,
  900: $purple,
  A100: $purple,
  A200: $purple,
  A400: $purple,
  A700: $purple,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: #e5ebe9,
    900: #ebeef0,
    A100: #efeff4,
    A200: rgba(black, 0.87),
    A400: $grey,
    A700: #e5ebe9,
  ),
);

$orange: #c16c18; // charts color 2
$am-orange: (
  50: lighten($orange, 50%),
  100: $orange,
  200: lighten($orange, 30%),
  300: lighten($orange, 10%),
  400: $orange,
  500: $orange,
  600: $orange,
  700: $orange,
  800: $orange,
  900: $orange,
  A100: $orange,
  A200: $orange,
  A400: $orange,
  A700: $orange,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: #e5ebe9,
    900: #ebeef0,
    A100: #efeff4,
    A200: rgba(black, 0.87),
    A400: $grey,
    A700: #e5ebe9,
  ),
);

$blue: #006a8e; // chart color 3
$am-blue: (
  50: $blue,
  100: $blue,
  200: $blue,
  300: $blue,
  400: $blue,
  // compare all
  500: #1d4e89,
  600: #1d4e89,
  700: #1d4e89,
  800: #1d4e89,
  900: #1d4e89,
  A100: $blue,
  A200: $blue,
  A400: $blue,
  A700: $blue,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: rgba(black, 0.87),
    900: rgba(black, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  ),
);

$blue-green: #68bbae; // chart color 4
$am-blue-green: (
  50: $blue-green,
  100: $blue-green,
  200: $blue-green,
  300: $blue-green,
  400: $blue-green,
  500: $blue-green,
  600: $blue-green,
  700: $blue-green,
  800: $blue-green,
  900: $blue-green,
  A100: $blue-green,
  A200: $blue-green,
  A400: $blue-green,
  A700: $blue-green,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: rgba(black, 0.87),
    900: rgba(black, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  ),
);

$C13: #84d87a; // allocation performance
$am-c13: (
  50: $C13,
  100: $C13,
  200: $C13,
  300: $C13,
  400: $C13,
  500: $C13,
  600: #87be7f,
  700: #87be7f,
  800: #8fcc87,
  900: #8fcc87,
  A100: $C13,
  A200: $C13,
  A400: $C13,
  A700: $C13,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$C14: #d87a7a; // allocation risk
$am-c14: (
  50: $C14,
  100: $C14,
  200: $C14,
  300: $C14,
  400: $C14,
  500: $C14,
  600: $C14,
  700: #c95a51,
  800: #c95a51,
  900: #964f4c,
  A100: $C14,
  A200: $C14,
  A400: $C14,
  A700: $C14,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

//Background palette for light themes.
$am-light-theme-background: (
  status-bar: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 300),
  app-bar: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 100),
  background: $grey,
  hover: rgba(black, 0.04),
  card: white,
  dialog: white,
  disabled-button: mat.m2-get-color-from-palette($am-white, 700),
  raised-button: mat.m2-get-color-from-palette($am-white, 900),
  focused-button: rgba(black, 0.12),
  selected-button: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 300),
  selected-disabled-button: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 400),
  disabled-button-toggle: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 200),
  unselected-chip: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 300),
  disabled-list-option: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 200),
);

// Background palette for dark themes.
$am-dark-theme-background: (
  status-bar: black,
  app-bar: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 900),
  background: #303030,
  hover: rgba(white, 0.04),
  card: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 800),
  dialog: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 800),
  disabled-button: rgba(white, 0.12),
  raised-button: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 800),
  focused-button: rgba(white, 0.12),
  selected-button: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 900),
  selected-disabled-button: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 800),
  disabled-button-toggle: black,
  unselected-chip: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 700),
  disabled-list-option: black,
);

// Foreground palette for light themes.
$am-light-theme-foreground: (
  base: mat.m2-get-color-from-palette($am-grey, 600),
  divider: rgba(black, 0.12),
  dividers: rgba(black, 0.12),
  disabled: rgba(black, 0.38),
  disabled-button: mat.m2-get-color-from-palette($am-grey, 100),
  disabled-text: rgba(black, 0.38),
  elevation: black,
  hint-text: rgba(black, 0.38),
  secondary-text: rgba(black, 0.54),
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: mat.m2-get-color-from-palette($am-grey, 600),
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

// Foreground palette for dark themes.
$am-dark-theme-foreground: (
  base: white,
  divider: rgba(white, 0.12),
  dividers: rgba(white, 0.12),
  disabled: rgba(white, 0.5),
  disabled-button: rgba(white, 0.3),
  disabled-text: rgba(white, 0.5),
  elevation: black,
  hint-text: rgba(white, 0.5),
  secondary-text: rgba(white, 0.7),
  icon: white,
  icons: white,
  text: white,
  slider-min: white,
  slider-off: rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3),
);
