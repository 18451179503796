@use '@angular/material' as mat;

@import 'core/core';
@import 'core/theming/palette';
@import 'core/theming/all-themes';

@include mat.core();
@include am-core();

// Include components base overrides
@import 'lib/button-toggle-group/button-toggle-group-base';
@import 'lib/button-toggle/button-toggle-base';
@import 'lib/button/button-base';
@import 'lib/dialog/dialog-base';
@import 'lib/card/card-base';
@import 'lib/expansion-panel/expansion-panel-base';
@import 'lib/form-field/form-field-base';
@import 'lib/list/list-base';
@import 'lib/menu/menu-base';
@import 'lib/snack-bar/snack-bar-base';
@import 'lib/tabs/tabs-base';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$am-light-primary: mat.m2-define-palette($am-purple, 400, 100, 900);
$am-light-accent: mat.m2-define-palette($am-purple, 400, 100, 900);

$am-dark-primary: mat.m2-define-palette($am-green, 400, 100, 900);
$am-dark-accent: mat.m2-define-palette($am-green, 400, 100, 900);

$am-test-primary: mat.m2-define-palette($am-orange, 400, 100, 900);
$am-test-accent: mat.m2-define-palette($am-orange, 400, 100, 900);

// The warn palette is optional (defaults to red).
$am-warn: mat.m2-define-palette($am-c14);

$typography-config: am-typography-config();
$density: -2;

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
$am-light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $am-light-primary,
      accent: $am-light-accent,
      warn: $am-warn,
    ),
    typography: $typography-config,
    density: $density,
  )
);

$am-dark-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $am-dark-primary,
      accent: $am-dark-accent,
      warn: $am-warn,
    ),
    typography: $typography-config,
    density: $density,
  )
);

$am-test-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $am-test-primary,
      accent: $am-test-accent,
      warn: $am-warn,
    ),
    typography: $typography-config,
    density: $density,
  )
);

@include mat.all-component-themes($am-dark-theme);

.am-light-theme {
  @include mat.core-color($am-light-theme);
  @include mat.all-component-colors($am-light-theme);
  @include am-material-theme($am-light-theme);
}

.am-test-theme {
  @include mat.core-color($am-test-theme);
  @include mat.all-component-colors($am-test-theme);
  @include am-material-theme($am-test-theme);
}

.am-dark-theme {
  @include mat.core-color($am-dark-theme);
  @include am-material-theme($am-dark-theme);
}
