.mat-mdc-button-base {
  width: 150px;
  height: 38px !important;
  padding-top: 3px;
  line-height: 32px;
}

@media only screen and (max-width: $large-tablet-size-up) {
  .mdc-button {
    width: 120px;
  }
}
