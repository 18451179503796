@use '@angular/material' as mat;

@mixin am-forecast-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);

  am-forecast {
    .forecast-options {
      --mdc-filled-text-field-container-color: #{$primary};
      --mat-select-enabled-trigger-text-color: #{$contrast};
      --mat-select-enabled-arrow-color: #{$contrast};
      --mat-select-focused-arrow-color: #{$contrast};
    }

    .graph {
      background-color: $contrast;
    }
  }
}
