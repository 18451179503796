@use '@angular/material' as mat;
@import '../../../styles/core/layout/breakpoints';

@mixin am-login-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);
  $otherContrast: mat.m2-get-contrast-color-from-palette($palette, 800);

  am-login {
    .login-container {
      background-color: $contrast;

      .am-app-name-small::before {
        border-bottom-color: $otherContrast;
      }
      .am-app-name-small {
        color: $primary;
      }
    }
  }
}

@mixin am-login-typography($config) {
  am-login {
    @media only screen and (max-width: $small-desktop-size-down) {
      .am-app-name-small {
        font-size: 20px;
      }
    }
  }
}
