@use '@angular/material' as mat;

@mixin am-tab-header-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  am-tab-header .header mat-icon {
    color: $primary !important;
  }
}

@mixin am-tab-header-typography($config) {
  am-tab-header .header mat-icon {
    font-size: mat.m2-font-size($config, very-huge-icon) !important;
  }
}
