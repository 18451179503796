@use '@angular/material' as mat;

@mixin am-select-theme($theme) {
  $palette: map-get($theme, primary);

  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);

  --mat-select-enabled-trigger-text-color: #{$contrast};
  --mat-select-enabled-arrow-color: #{$contrast};
  --mat-select-focused-arrow-color: #{$contrast};

  .mdc-text-field--filled {
    --mdc-filled-text-field-container-shape: 0 !important;
    --mdc-filled-text-field-active-indicator-height: 0;
    --mdc-filled-text-field-focus-active-indicator-height: 0;
  }

  .mat-mdc-option {
    min-height: 36px;
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    --mat-option-selected-state-label-text-color: black;
  }

  .mat-mdc-select {
    --mat-select-trigger-text-weight: 400;
    --mat-select-trigger-text-size: 16px;
  }

  .mat-mdc-select-panel {
    --mat-option-label-text-weight: 400;
    --mat-option-label-text-size: 14px;
    --mat-option-label-text-line-height: 19px;
  }

  .mat-mdc-select-panel.multi-select-with-search {
    height: 400px;
    max-height: unset;
    padding-top: 0;
    padding-bottom: 0;
  }
}
