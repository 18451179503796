@use '@angular/material' as mat;

@mixin am-allocation-card-theme($theme) {
  $palette: map-get($theme, primary);

  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);

  $comparison: mat.m2-get-color-from-palette($palette, 900);
  $firstAllocation: mat.m2-get-color-from-palette($palette, 50);
  $secondAllocation: mat.m2-get-color-from-palette($palette, 200);
  $thirdAllocation: mat.m2-get-color-from-palette($palette, 300);

  am-allocation-card {
    .allocation-card {
      background-color: $contrast;

      .allocation-number,
      .material-icons {
        border-color: $contrast;
        color: $contrast;
      }

      .compare-all-icon {
        border-color: $contrast;
      }
    }

    .allocation-card.active {
      color: $contrast;
    }

    .compare-all-card {
      border-color: $comparison;
      color: $comparison;

      .material-icons {
        background-color: $comparison;
      }
    }

    .allocation-card.active {
      background-color: $comparison;
      box-shadow: 0 2px 7px 0 $comparison;
    }

    .am-alloc-1-color {
      color: $firstAllocation;
      border-color: $firstAllocation;

      .allocation-number {
        background-color: $firstAllocation;
      }
    }

    .am-alloc-1-color.active {
      background-color: $firstAllocation;
      box-shadow: 0 2px 7px 0 $firstAllocation;
    }

    .am-alloc-2-color {
      color: $secondAllocation;
      border-color: $secondAllocation;

      .allocation-number {
        background-color: $secondAllocation;
      }
    }

    .am-alloc-2-color.active {
      background-color: $secondAllocation;
      box-shadow: 0 2px 7px 0 $secondAllocation;
    }

    .am-alloc-3-color {
      color: $thirdAllocation;
      border-color: $thirdAllocation;

      .allocation-number {
        background-color: $thirdAllocation;
      }
    }

    .am-alloc-3-color.active {
      background-color: $thirdAllocation;
      box-shadow: 0 2px 7px 0 $thirdAllocation;
    }
  }
}

@mixin am-allocation-card-typography($config) {
  am-allocation-card {
    .allocation-card {
      .allocation-name,
      .compare-all {
        font: {
          size: mat.m2-font-size($config, category);
          weight: mat.m2-font-weight($config, category);
        }
      }

      .allocation-number,
      .material-icons {
        font-size: 30px;
      }

      @media only screen and (max-width: $large-tablet-size-down) {
        .allocation-infos {
          font-size: 11px;
        }

        .allocation-name,
        .compare-all {
          font-size: mat.m2-font-size($config, body-bigger);
        }

        .allocation-number,
        .material-icons {
          font-size: 24px;
        }
      }
    }
  }
}
