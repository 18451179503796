@use '@angular/material' as mat;

@mixin am-admin-theme($theme) {
  $palette: map-get($theme, primary);

  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);

  am-admin {
    .admin-container {
      background-color: $contrast;
    }
  }
}

@mixin am-admin-typography($config) {
  am-admin {
    .mat-expansion-panel-header-title,
    .mdc-tab__text-label {
      font-family: mat.m2-font-family($config, body-1);
    }
  }

  .am-dark-theme am-admin .mat-mdc-tab-header {
    font: {
      family: mat.m2-font-family($config, subheading-1) !important;
      size: mat.m2-font-size($config, subheading-1);
    }
  }
}
