@use '@angular/material' as mat;

@mixin am-dialog-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);
  $lightContrast: mat.m2-get-contrast-color-from-palette($palette, A100);

  .close-modal {
    position: absolute !important;
    right: 15px;
    top: 12px;
    z-index: 1;
    color: $contrast;
    cursor: pointer;
  }

  .mdc-dialog__title {
    background-color: $primary;
    --mdc-dialog-subhead-color: #{$contrast};
  }

  .mdc-dialog__actions {
    --mdc-filled-button-container-color: #{$lightContrast};
  }
}

@mixin am-dialog-typography($config) {
  .mdc-dialog__title {
    --mdc-dialog-subhead-size: 16px;
    --mdc-dialog-subhead-line-height: 19px;
  }

  .mat-mdc-dialog-container .mdc-dialog__content {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
}
