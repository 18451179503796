@use '@angular/material' as mat;

@mixin am-snack-bar-theme($theme) {
  $palette: map-get($theme, primary);

  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);
  $darkContrast: mat.m2-get-contrast-color-from-palette($palette, A400);

  $lime: mat.m2-get-color-from-palette($am-c13, 800);
  $orange: mat.m2-get-color-from-palette($am-orange, 400);
  $red: mat.m2-get-color-from-palette($am-c14, 700);

  .mat-mdc-snack-bar-container {
    &.success-snackbar,
    &.warning-snackbar,
    &.error-snackbar {
      color: $contrast;

      .action {
        color: $contrast;

        .mat-mdc-button-base {
          padding: 0;
          min-width: 20px;
          background-color: $contrast;
          color: $darkContrast;
        }
      }
    }

    &.success-snackbar {
      --mdc-snackbar-container-color: #{$lime};
    }

    &.warning-snackbar {
      --mdc-snackbar-container-color: #{$orange};
    }

    &.error-snackbar {
      --mdc-snackbar-container-color: #{$red};
    }
  }
}

@mixin am-snack-bar-typography($config) {
  .mat-mdc-snack-bar-container {
    .content {
      .title {
        font: {
          size: mat.m2-font-size($config, title);
          weight: mat.m2-font-weight($config, title);
        }
      }
    }
  }
}
