@use '@angular/material' as mat;
@use 'sass:color';

@mixin am-liquidity-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  $transparentPrimary: color.adjust($primary, $alpha: 0.3);

  am-liquidity {
    .mat-headline.help {
      color: $primary;
      box-shadow: -2px 2px 10px 0px $transparentPrimary;
    }
  }
}
