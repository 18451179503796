@use '@angular/material' as mat;
@mixin am-button-toggle-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);
  $secondContrast: mat.m2-get-contrast-color-from-palette($palette, A700);
  $mediumContrast: mat.m2-get-contrast-color-from-palette($palette, A400);

  .mat-button-toggle {
    background-color: $secondContrast !important;

    .mat-button-toggle-button {
      color: $mediumContrast !important;
      font-weight: 400;
    }
  }

  .mat-button-toggle-checked {
    background-color: $primary !important;

    .mat-button-toggle-label-content {
      color: $contrast !important;
      font-weight: 700;
    }
  }

  .selected-profile-button.mat-button-toggle-disabled.mat-button-toggle-checked {
    background-color: $primary !important;
  }
}
