@use '@angular/material' as mat;

@mixin am-user-guides-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);

  am-user-guides {
    .content mat-card {
      background-color: $primary !important;
      color: $contrast !important;
    }
  }
}

@mixin am-user-guides-typography($config) {
  am-user-guides {
    .content mat-card {
      font: {
        family: mat.m2-font-family($config, subheading-1);
        size: mat.m2-font-size($config, subheading-1);
        weight: mat.m2-font-weight($config, subheading-1);
      }
    }
  }
}
