@use '@angular/material' as mat;

@mixin am-allocation-setup-theme($theme) {
  $palette: map-get($theme, primary);

  $primary: mat.m2-get-color-from-palette($palette, 400);
  $contrast: mat.m2-get-contrast-color-from-palette($palette, 400);

  $C13: map-get($am-c13, 700);
  $C14: map-get($am-c14, 900);

  am-allocation-setup {
    input {
      color: $contrast;
      text-align: center;
    }

    .asset-classes-header {
      background-color: $contrast;
    }

    .mat-mdc-text-field-wrapper {
      border-radius: 0 !important;
    }

    .allocation-setup {
      background-color: $contrast;

      .allocation-name {
        color: $C14;
      }
      .valid {
        color: $C13;
      }

      .more-button {
        button.mat-mdc-button .mat-mdc-button-persistent-ripple::before {
          background-color: $contrast !important;
        }
      }

      .reset-button,
      .add-button {
        background-color: $primary;
        color: $contrast;
      }
    }

    .panel .asset {
      color: $contrast;
    }
  }
}

@mixin am-allocation-setup-typography($config) {
  am-allocation-setup {
    input {
      font-size: mat.m2-font-size($config, input);
    }

    .allocation-setup {
      .asset-classes-title {
        font-size: mat.m2-font-size($config, headline);
      }

      .panel {
        .mat-expansion-panel-header,
        .mat-expansion-panel-content {
          font: {
            size: mat.m2-font-size($config, asset-panel);
            weight: mat.m2-font-weight($config, asset-panel);
          }
        }
        .category-name,
        .category-percent {
          font: {
            weight: mat.m2-font-weight($config, category);
          }
        }
      }
    }
  }
}
